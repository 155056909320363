<template>
  <div class="auth-page-wrapper">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container" style="max-width: 1380px">
        <div class="col-12 logo-box">
          <div class="text-center text-white-50">
            <!-- <img :src="logoImg" alt="" style="height: 128px" /> -->
            <p class="login-logo mb-0" v-if="Env == 'en'">
              Start your digital transformation journey
            </p>
            <p class="login-logo mb-0" v-else>开启您数字化转型航程</p>
          </div>
        </div>
        <div class="col-5 login-box">
          <div class="card mt-4">
            <div class="card-body p-4">
              <div class="text-center mt-2">
                <h5 class="text-primary">{{ $t("login.title") }}</h5>
                <p class="text-muted">{{ $t("login.subtitle") }}</p>
              </div>
              <div class="p-2 mt-4">
                <form id="login">
                  <!-- 用户 -->
                  <div class="mb-3">
                    <label for="email" class="form-label">{{
                      $t("login.username")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      autocomplete="new-email"
                      :placeholder="$t('login.hint_username')"
                      v-model="username"
                      :class="{
                        'is-invalid': submitted && v$.username.$error,
                      }"
                    />
                    <div
                      v-for="(item, index) in v$.username.$errors"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <!-- 密码 -->
                  <div class="mb-3">
                    <label class="form-label" for="password-input">{{
                      $t("login.password")
                    }}</label>
                    <div class="position-relative auth-pass-inputgroup mb-3">
                      <input
                        :type="passwordType"
                        v-model="password"
                        autocomplete="new-password"
                        class="form-control pe-5"
                        :class="{
                          'is-invalid': submitted && v$.password.$error,
                        }"
                        :placeholder="$t('login.hint_password')"
                        id="password-input"
                      />
                      <button
                        class="
                          btn btn-link
                          position-absolute
                          top-0
                          text-decoration-none text-muted
                        "
                        style="right: 5%"
                        type="button"
                        id="password-addon"
                      >
                        <i
                          class="ri-eye-fill align-middle"
                          @click="viewPassword()"
                        ></i>
                      </button>
                      <div
                        v-if="submitted && v$.password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="v$.password.required.$message">{{
                          v$.password.required.$message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-check mt-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :checked="isRemember"
                      @click="remember()"
                      id="auth-remember-check"
                    />
                    <label class="form-check-label" for="auth-remember-check">{{
                      $t("login.remember")
                    }}</label>
                  </div>

                  <div class="mt-5">
                    <button
                      class="btn btn-success w-100"
                      type="button"
                      @click="handlerLogin()"
                    >
                      {{ $t("login.login") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Powered by
                <i class="mdi mdi-heart text-danger"></i>
                Digital Clothing
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { login } from "@/service";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      username: sessionStorage.getItem("username") || "",
      password: sessionStorage.getItem("password") || "",
      submitted: false,
      passwordType: "password",
      isRemember: sessionStorage.getItem("username"),
      formValidataAlert: false,
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-alert-line",
      },
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Username is required", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    Env() {
      let env =
        window.sessionStorage.getItem("language") ||
        process.env.VUE_APP_I18N_LOCALE;
      return env;
    },
  },
  methods: {
    /* 切换密码展示 */
    viewPassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    /* 记住密码 */
    remember() {
      this.isRemember = !this.isRemember;
    },
    /* 登录 */
    handlerLogin() {
      /* todo校验输入账户密码 */
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      let params = {
        username: this.username,
        password: this.password,
      };
      login(params)
        .then((res) => {
          if (res) {
            const data = JSON.stringify(res);
            this.$store.commit("login/set_menu_list", data);
            sessionStorage.setItem("set_menu_list", data);
            if (this.isRemember) {
              sessionStorage.setItem("username", this.username);
              sessionStorage.setItem("password", this.password);
            } else {
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("password");
            }
            sessionStorage.setItem("is_login", true);
            this.$router.push("/homePage");
          }
        })
        .catch((e) => {
          this.formValidataAlert = true;
          this.alertInfo = {
            variant: "danger",
            content: e.message,
            icon: "ri-alert-line",
          };
          setTimeout(() => {
            this.formValidataAlert = false;
          }, 3000);
        });
    },
    testLogin() {
      this.$router.push("/homePage");
    },
  },
};
</script>

<style scoped lang='scss'>
.auth-page-content {
  height: 100vh;
  .container {
    height: 80%;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .logo-box {
      margin-bottom: 10rem;
    }
    .login-box {
      flex: 1;
    }
  }

  @media screen and (min-width: 1600px) {
    .container {
      height: 60%;
    }
  }
}
</style>
